
export default {
    innerNetworkCheck(openSessionId) {
        return $$.get(`${process.env.VUE_APP_HTTP_INNER_CHECK}/api-translate-authority/operation-common/inner-access`, {
            params: {
                Opsession: openSessionId
            }
        })
    },
    getInnerCheckUrl(openSessionId) {
        return `${process.env.VUE_APP_HTTP_INNER_CHECK}/api-translate-authority/operation-common/inner-access?Opsession=${openSessionId}`;
    },
    getUserInfo() {
        return $$.get('/api-translate-authority/operation-common/user-info');
    },
}