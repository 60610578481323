<template>
  <v-app id="inspire" :key="$i18n.locale">
    <v-app-bar app color="indigo" dark dense style="z-index : 999" class="app-bar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="font-size : 16px;">ECHO{{env}}</v-toolbar-title>
      <v-menu-list :menus="menus"></v-menu-list>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-avatar :height="36" :width="36">
          <img :src="user.thumbnail" alt="avatar">
        </v-avatar>
      </v-btn>

      <v-menu offset-y :close-on-content-click="false" bottom left>
        <template v-slot:activator="{ on }">
          <v-btn text style="text-transform : none" v-on="on">
            {{user.nickname}}
            <v-icon dark>arrow_drop_down</v-icon>
          </v-btn>
        </template>

        <user-panel></user-panel>
      </v-menu>

      <v-menu offset-y bottom left rounded>
        <template v-slot:activator="{ on }">
          <v-btn text style="text-transform : none" v-on="on">
            <i class="lang-change-button"></i>
          </v-btn>
        </template>

        <v-list dense link>
          <v-list-item-group color="primary" v-model="currentLanguage">
            <v-list-item v-for="(item , index) in language" :key="index" :value="item.key">
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </v-menu>

      <v-btn text style="text-transform : none" @click="logout">
        {{$t('ECHO_TRANSLATION_PLATFORM_1614323108287_515')}}
      </v-btn>
    </v-app-bar>
    <v-main>
      <div class="breadcrumb" style="margin-top : 10px;margin-left : 10px">
        <el-breadcrumb class="breadcrumb-inner">
          <el-breadcrumb-item v-for="(item , index) in breadcrumbList" :key="index" :to="getPath(item)">{{$te(global.utils.router.getKey(item.lang_key)) || item.desc}}</el-breadcrumb-item>
        </el-breadcrumb>
        <i class="el-icon-refresh refresh-button" @click="refresh" />

      </div>
      <v-container fluid style="align-items : baseline; padding-bottom: 0px;">
        <router-view v-if="allow"></router-view>
        <forbidden v-else></forbidden>
      </v-container>
    </v-main>
  </v-app>
</template>
<style lang="scss" scoped>
#inspire {
  a {
    color: white;
  }
  .select {
    color: #ffd04b;
  }
}

.refresh-button {
  cursor: pointer;
  display: none;
  margin-left: 5px;
  animation: show 0.5s forwards;
}
@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.breadcrumb-inner {
  display: inline-block;
}
.breadcrumb:hover .refresh-button {
  display: inline;
}

.lang-change-button {
  background: url("../../assets/img/lang-icon.png") no-repeat;
  background-size: 100%;
  height: 24px;
  width: 24px;
}
</style>
<script type="text/javascript">
import pageNames from "@/config/page_name";
import Forbidden from "@/components/errors/Forbidden.vue";
import VMenuList from "./menu/Menu.vue";
import UserPanel from "./sub/UserPanel.vue";
import $$ from "axios";
import utils from "@/common/utils";
import startQiankun from "@/micro";
import { mapGetters } from 'vuex'; 

export default {
  name: "v-layout",
  data: () => ({
    drawer: false,
    allow: true,
    currentLanguage: utils.getCookie("translateLang") || "zh_cn",
    language: [
      {
        name: "中文简体",
        key: "zh_cn",
      },
      {
        name: "中文繁體",
        key: "zh_tw",
      },
      {
        name: "English",
        key: "en_us",
      },
      /*{
              name: "ภาษาไทย",
              key: "th_th"
            },
            {
              name: "Bahasa Malaysia",
              key: "ms_my"
            },
            {
              name: "Bahasa Indonesia",
              key: "id_id"
            },
            {
              name: "Tiếng Việt",
              key: "vi_vn"
            },
            {
              name: "日本語",
              key: "ja"
            },
            {
              name: "한국어",
              key: "ko"
            },
            {
              name: "Español",
              key: "es_mx"
            }*/
    ],
  }),
  beforeUpdate() {
    this.allow = this.$store.state.allow;
  },
  components: {
    Forbidden,
    VMenuList,
    UserPanel,
  },
  computed: {
    ...mapGetters(['isIqiyiAdmin']),
    ...mapGetters('networkCheck', ['innerCheckSuccess']),
    breadcrumbList() {
      const routerMap = pageNames.reduce((map, {name, router}) => {
        map[name] = router;
        return map;
      }, {});
      const breadcrumbList = this.$store.state.breadcrumb || [];
      return breadcrumbList.map((item, index) => ({
        ...item,
        router: index === 0 ? '/' : (index === breadcrumbList.length - 1 ? null : routerMap[item.name])
      }));
    },
    user() {
      return this.$store.state.user;
    },
    env() {
      if (process.env.NODE_ENV == "development") {
        return "(开发环境)";
      }
      if (process.env.NODE_ENV == "test") {
        return "(测试环境)";
      }
      if (process.env.NODE_ENV == "stage") {
        return "(预发环境)";
      }
      return "";
    },
    menus() {
      return this.translateMenu2Router(this.$store.state.menulist);
    },
  },
  watch: {
    currentLanguage(val) {
      utils.setCookie("translateLang", val, 86400 * 30);
      window.location.reload();
    },
  },
  created() {
    this.allow = this.$store.state.allow;

    this.sdk.manager.event.un("logout");
    this.sdk.on("logout", () => {
      this.logoutSuccess();
    });
  },
  methods: {
    checkMessage() {
      if(this.isIqiyiAdmin && !this.innerCheckSuccess) {
        this.$message.error("内网验证失败，请登录内网进行验证");
      }
    },
    getPath(item) {
      if(item == null) {
        return null;
      }
      const {router, name} = item;
      if(router) {
        return router;
      }
      if(name?.includes?.("/")) {
        return name;
      }
      return null;
    },
    refresh() {
      if (this.allow) {
        const {path, query} = this.$route;
        this.$router.push({
          path: "/",
        });
        setTimeout(() => {
          this.$router.push({
            path,
            query
          });
        }, 100);
      }
    },
    logout() {
      console.log("logout");
      this.sdk.logout();
    },
    logoutSuccess() {
      $$.get(process.env.VUE_APP_HTTP_PRE + "/api-intl-translate/logout")
        .then(async () => {
          await $$.get(
            process.env.VUE_APP_HTTP_PRE_INNER +
              "/api-intl-translate/operation-anonymous/core/clean-opcookie"
          );
          utils.setCookie("OPSESSION", "", -1);
          if (this.$route.name == "login") return false;
          this.$router.push({
            name: "login",
            query: { path: encodeURI(location.href) },
          });
        })
        .catch((msg) => {
          console.log(msg);
        });
    },
    getRouter(name) {
      let router = pageNames.filter((val) => {
        return val.name == name;
      });
      if (router.length > 0) return router[0].router;
      else return name;
    },
    translateMenu2Router(menu) {
      //传参为对象或数组，则进行处理，否则直接返回原始值
      if (typeof menu == "object") {
        //如果是数组，循环再次处理
        if (menu instanceof Array) {
          menu.map((v) => {
            v = this.translateMenu2Router(v);
            return v;
          });
        } else {
          menu.router = utils.router.getRouter(menu.name);
          menu.lang_key = utils.router.getKey(menu.name);
          if (menu.childs) {
            menu.childs = this.translateMenu2Router(menu.childs);
          }
        }
      }
      return menu;
    },
  },
  mounted() {
    let microApps = utils.sys
      .getMicroAppImmediately()
      .filter((v) => v.status == 1)
      .map((v) => ({
        name: v.projKey,
        entry: utils.addParams(
          v.address.indexOf("http") === 0 || v.address.indexOf("//") === 0
            ? v.address
            : process.env.VUE_APP_SUB_APPLICATION_PRE + v.address,
          {
            version:
              process.env.NODE_ENV === "production" ? v.version : Date.now(),
          }
        ),
        container: "#frame",
        activeRule: v.path,
      }));
    startQiankun(microApps, window.__subParams__ || {});
    setTimeout(() => {
      this.checkMessage();
    }, 6000)
  },
};
</script>