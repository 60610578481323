<template>
  <div id="translate-app">
    <router-view v-if="networkCheckFinish"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  data() {
    return {
      networkCheckFinish: false,
    }
  },
  computed: {
    ...mapGetters(['isIqiyiAdmin', 'init']),
    ...mapGetters('networkCheck', ['innerCheckSuccess']),
  },
  watch: {
    init() {
      this.networkCheck();
    }
  },
  mounted() {
    this.networkCheck();
  },
  methods: {
    async networkCheck() {
      if(!this.init) {
        return;
      }
      try {
        if (this.isIqiyiAdmin) {
          if (!this.innerCheckSuccess) {
            await this.$store.dispatch("networkCheck/check");
          } else {
            this.$store.dispatch("networkCheck/check");
          }
        }
      } finally {
        this.networkCheckFinish = true;
      }
    },
  }
};
</script>
