import utils from '@/common/utils';
import service from './service';

const CHECK_TIME_KEY = "networkCheckInfo";
const MAX_TIME_DIFF = 1000 * 60 * 60 * 18;

export default {
    namespaced: true,
    state: {
        openSessionId: null,
        innerAccessExpireTime: null,
    },
    mutations: {
        setInnerAccessExpireTime(state, payload) {
            state.innerAccessExpireTime = payload;
        },
        setOpenSessionId(state, payload) {
            state.openSessionId = payload;
        },
    },
    actions: {
        async updateCheck({ commit }) {
            const { innerAccessExpireTime, sessionId } = await service.getUserInfo();
            commit('setInnerAccessExpireTime', innerAccessExpireTime);
            commit('setOpenSessionId', sessionId);
        },
        async getOpenSessionId({ state, commit, dispatch }) {
            let { openSessionId } = state;
            if (openSessionId != null) {
                return openSessionId;
            }
            openSessionId = utils.getCookie("OPSESSION");
            if (openSessionId != null) {
                commit('setOpenSessionId', openSessionId);
                return openSessionId;
            }
            await dispatch('updateCheck');
            return state.openSessionId;
        },
        async check({ dispatch }) {
            try {
                const openSessionId = await dispatch('getOpenSessionId');
                await service.innerNetworkCheck(openSessionId);
            } catch (e) {
                console.error("inner check failed", e);
            } finally {
                dispatch('updateCheck');
            }
        },
        async getInnerCheckUrl({ dispatch }) {
            const openSessionId = await dispatch('getOpenSessionId');
            return service.getInnerCheckUrl(openSessionId);
        },
    },
    getters: {
        innerCheckSuccess(state) {
            const { innerAccessExpireTime } = state;
            return innerAccessExpireTime != null && innerAccessExpireTime > new Date().getTime();

        },
    }
}